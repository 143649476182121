import React, { useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from 'src/assets/icons/arrow-right-icon.svg?react';
import CalendarIcon from 'src/assets/icons/calendar-icon.svg?react';
import PhoneCallbackIcon from 'src/assets/icons/phone-callback-icon.svg?react';
import TimeIcon from 'src/assets/icons/time-icon.svg?react';
import { API_VERSION } from 'src/services';
import { formatDate, generatePath, monthsToYears } from 'src/utils';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import FieldsSet, {
  createCurrFormatter,
  createLabelFormatter,
  FieldDataItem,
} from 'src/components/FieldsSet';
import FilesDataRoom from 'src/components/FilesDataRoom';
import LoadingButton from 'src/components/LoadingButton';
import ResponsiveStack from 'src/components/ResponsiveStack';
import {
  SideModalSkeleton,
  SideModalTabs,
  SideModalTabsList,
} from 'src/components/SideModal';
import { Tab, TabPanel } from 'src/components/Tabs';
import { Currency } from 'src/entities/currency';
import {
  borrowerTypeLabels,
  loanTypeLabels,
  sectorLabels,
} from 'src/entities/dicts';
import {
  FUNDING_OPPS_DOWNLOAD_NDA,
  FundingOppItemField,
  FundingOppNDAStatus,
  getIrrDisplayValue,
  useGetFundingOppDetailsQuery,
  useRequestCallMutation,
} from 'src/entities/fundingOpps';
import { statusMap } from './data';
import FundingOppDetailsUploadNda from './FundingOppDetailsUploadNda';
import FundingOppDetailsLoanApp from './FundingOppDetailsLoanApp';
import { DataRoomGroup } from '../../entities/dataRoom';
import { FundingOppStatus } from '../../entities/fundingOpps';

type Props = {
  id: number;
};

const getFields = (currency: Currency): FieldDataItem[] => [
  {
    id: FundingOppItemField.LoanAmount,
    label: 'Loan Amount',
    formatter: createCurrFormatter(currency),
  },
  {
    id: FundingOppItemField.Irr,
    label: 'Indicative Return',
    formatter: (value) => getIrrDisplayValue(...(value as number[])),
  },
  {
    id: FundingOppItemField.LoanTerm,
    label: 'Duration',
    formatter: (value) => `${monthsToYears(value as number)} years`,
  },
  {
    id: FundingOppItemField.LoanTypeCode,
    label: 'Type Of Debt Solution',
    formatter: createLabelFormatter(loanTypeLabels),
  },
  {
    id: FundingOppItemField.RegionCode,
    label: 'Region',
  },
  {
    id: FundingOppItemField.BorrowerTypeCode,
    label: 'Borrower / Seller Type',
    formatter: createLabelFormatter(borrowerTypeLabels),
  },
  {
    id: FundingOppItemField.SectorCode,
    label: 'Sector',
    formatter: createLabelFormatter(sectorLabels),
  },
  {
    id: FundingOppItemField.AdditionalInfo,
    label: 'Additional Information',
  },
  {
    id: FundingOppItemField.BriefDescriptionLink,
    label: 'Brief Description',
    Component: ({ value }) => (
      <Link
        href={`${import.meta.env.REACT_APP_API_PATH}${API_VERSION}${value}`}
      >
        Download PDF
      </Link>
    ),
  },
];

function FundingOppDetails({ id }: Props) {
  const [tab, setTab] = useState(2);
  const { showAlert } = useAlert();
  const { data, isFetching } = useGetFundingOppDetailsQuery(id);
  const [requestCall, { isLoading }] = useRequestCallMutation();
  const {
    statusCode = FundingOppStatus.New,
    ndaFromCreditorDocId,
    ndaStatusCode,
    loanApplication,
    fullDetailsAvailable,
    ...rest
  } = data || {};
  const ndaIsPending = !!ndaFromCreditorDocId && !fullDetailsAvailable;
  const { color, label } = statusMap[statusCode];

  const handleRequestCall = () =>
    requestCall(id).then(() =>
      showAlert({ type: AlertType.Info, text: 'The call has been requested.' }),
    );

  useEffect(() => {
    setTab(2);
  }, [id]);

  if (!data || isFetching) {
    return <SideModalSkeleton />;
  }
  return (
    <>
      <ResponsiveStack
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
      >
        <Chip
          sx={{ minWidth: { xs: '60%', sm: '25%' }, mr: 1 }}
          size="xs"
          color={color}
          label={label}
        />
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          flex={1}
          ml={-1.5}
        >
          <ListItem>
            <ListItemIcon>
              <SvgIcon component={CalendarIcon} />
            </ListItemIcon>
            <ListItemText
              primary={formatDate(data.createdDate)}
              secondary="Date presented"
            />
          </ListItem>
          <SvgIcon color="action" component={ArrowRightIcon} />
          <ListItem>
            <ListItemText
              primary={formatDate(data.targetedClosure)}
              secondary="Targeted closure"
            />
          </ListItem>
        </Stack>
      </ResponsiveStack>
      <Typography variant="h6" mb={2}>
        General information
      </Typography>
      <FieldsSet
        sx={{ mb: 2 }}
        rowStyle={{ mx: -2, px: 2 }}
        fields={getFields(data.currency)}
        data={rest}
        skipEmpty
        zebra
      />
      <LoadingButton
        size="small"
        color="secondary"
        loading={isLoading}
        onClick={handleRequestCall}
        startIcon={<SvgIcon component={PhoneCallbackIcon} />}
      >
        Request a call with Monivolt team
      </LoadingButton>
      <SideModalTabs
        value={tab}
        tabsPanel={
          <SideModalTabsList onChange={setTab}>
            <Tab disabled={!fullDetailsAvailable} label="Data Room" />
            <Tab disabled={!fullDetailsAvailable} label="Borrower" />
            <Tab label="Recent activity" />
          </SideModalTabsList>
        }
      >
        <TabPanel value={0}>
          <FilesDataRoom
            canUpdate={false}
            group={DataRoomGroup.fundingOpps}
            id={id}
          />
        </TabPanel>
        <TabPanel value={1}>
          {loanApplication && (
            <FundingOppDetailsLoanApp {...loanApplication.borrower} />
          )}
        </TabPanel>
        <TabPanel value={2}>
          {(statusCode === FundingOppStatus.New ||
            statusCode === FundingOppStatus.DueDiligence) && (
            <>
              {!ndaFromCreditorDocId && !fullDetailsAvailable && (
                <FundingOppDetailsUploadNda id={id} />
              )}
              {ndaIsPending && (
                <>
                  <Stack component={Paper} gap={1} p={2}>
                    <Typography variant="subtitle1">
                      <SvgIcon component={TimeIcon} sx={{ mr: 1 }} />
                      Your NDA is under review
                    </Typography>
                    Once it&apos;s accepted, you will be able to see all the
                    blocked details.
                  </Stack>
                  <Typography color="text.secondary" variant="body2" mt={2}>
                    You have uploaded the NDA.&nbsp;
                    <Link
                      href={generatePath(FUNDING_OPPS_DOWNLOAD_NDA, { id })}
                    >
                      Download file
                    </Link>
                  </Typography>
                </>
              )}
              {ndaStatusCode === FundingOppNDAStatus.Signed && (
                <Typography color="text.secondary" variant="body2">
                  Your NDA has been accepted and signed by Monivolt.&nbsp;
                  <Link href={generatePath(FUNDING_OPPS_DOWNLOAD_NDA, { id })}>
                    Download file
                  </Link>
                </Typography>
              )}
            </>
          )}
        </TabPanel>
      </SideModalTabs>
    </>
  );
}

export default FundingOppDetails;

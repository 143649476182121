import { OfferItem } from 'src/entities/offers';
import Stack from '@mui/material/Stack';
import TermSheetRowStatus from './TermSheetRowStatus';

function TermSheetRow({
  creditorCompanyFullName,
  acceptanceStatusCode,
}: OfferItem) {
  const initiatorName = creditorCompanyFullName ?? 'Borrower';
  return (
    <Stack direction="row" alignItems="center" gap={5}>
      {initiatorName}
      <TermSheetRowStatus status={acceptanceStatusCode} />
    </Stack>
  );
}

export default TermSheetRow;

import React from 'react';
import { getDefaultFormValues, useForm } from 'src/helpers';
import {
  CreateTermSheetForm,
  useCreateOfferMutation,
  OfferValuesFormParams,
} from 'src/entities/offers';
import { FormProvider } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import ModalDialog, { ModalCloseHandler } from '../ModalDialog';
import { AlertType, useAlert } from '../AlertsProvider';
import { offerFormValidation, offerValuesRowValidation } from './formData';
import FormArray, { FormArrayRowAddBtn } from '../FormArray';
import TermSheetCreateOfferRow from './TermSheetCreateOfferRow';

type Props = {
  id: number;
  open: boolean;
  onClose: ModalCloseHandler;
};

function TermSheetCreateOfferModal({ id, open, onClose }: Props) {
  const { showAlert } = useAlert();
  const [createTermSheet, { isLoading }] = useCreateOfferMutation();

  const formProps = useForm<OfferValuesFormParams>({
    validation: offerFormValidation,
  });
  const { handleSubmit } = formProps;

  const onCreateTermSheet = async ({ values }: OfferValuesFormParams) => {
    await createTermSheet({ id, values }).unwrap();
    showAlert({
      type: AlertType.Success,
      text: 'TermSheetContent created',
    });
    onClose();
  };

  return (
    <ModalDialog
      PaperProps={{ sx: { minHeight: '25rem' } }}
      contentProps={{ sx: { gap: 2 } }}
      onConfirm={handleSubmit(onCreateTermSheet)}
      open={open}
      onClose={onClose}
      title="Create Offer"
      confirmBtnText="Submit"
      showConfirmBtn
      confirmBtnLoading={isLoading}
    >
      <Typography variant="body1" color="textSecondary">
        Please enter suggested values of the offer
      </Typography>
      <FormProvider {...formProps}>
        <FormArray
          name={CreateTermSheetForm.Values}
          rowDefaultValues={getDefaultFormValues(offerValuesRowValidation)}
          rowComponent={TermSheetCreateOfferRow}
          addRowComponent={FormArrayRowAddBtn}
          addRowProps={{ label: 'Add value' }}
        />
      </FormProvider>
    </ModalDialog>
  );
}

export default TermSheetCreateOfferModal;

import TextField from '@mui/material/TextField';
import React from 'react';
import { CreateTermSheetForm } from 'src/entities/offers';
import ResponsiveStack from '../ResponsiveStack';
import FormControl from '../FormControl';
import { FormArrayRowHead } from '../FormArray';

type Props = {
  path: string;
  onRemoveRow: () => void;
  canRemoveRow?: boolean;
};

function TermSheetCreateOfferRow({ path, ...rest }: Props) {
  return (
    <>
      <FormArrayRowHead {...rest} />
      <ResponsiveStack>
        <FormControl
          data-testid="key-input"
          name={`${path}.${CreateTermSheetForm.Key}`}
          label="Key"
          component={TextField}
        />
        <FormControl
          data-testid="value-input"
          name={`${path}.${CreateTermSheetForm.Value}`}
          label="Value"
          component={TextField}
        />
      </ResponsiveStack>
    </>
  );
}

export default TermSheetCreateOfferRow;

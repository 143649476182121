import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod, Response } from 'src/services';
import { generatePath } from 'src/utils';
import {
  OFFERS_STORE_KEY,
  OFFERS_TERM_SHEET_TAG,
  OFFERS_TERMS_SHEET_API,
} from './constants';
import { CreateOfferParams, OfferItem, OffersTermSheet } from './types';

export const offersApi = createApi({
  reducerPath: OFFERS_STORE_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Pa }),
  tagTypes: [OFFERS_TERM_SHEET_TAG],
  endpoints: ({ query, mutation }) => ({
    getTermSheet: query<OfferItem[] | undefined, number>({
      query: (id) => ({
        url: generatePath(OFFERS_TERMS_SHEET_API, { id }),
        method: ReqMethod.Get,
      }),
      transformResponse: ({ data }: Response<OffersTermSheet>) =>
        data?.activeOffers,
      providesTags: [OFFERS_TERM_SHEET_TAG],
    }),
    createOffer: mutation<void, CreateOfferParams>({
      query: ({ id, values }) => ({
        url: generatePath(OFFERS_TERMS_SHEET_API, { id }),
        method: ReqMethod.Post,
        body: { values },
      }),
      invalidatesTags: [OFFERS_TERM_SHEET_TAG],
    }),
  }),
});

export const { useGetTermSheetQuery, useCreateOfferMutation } = offersApi;

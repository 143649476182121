import React, { createContext, ReactNode, useMemo } from 'react';
import { useModal } from 'src/hooks';
import TermSheetCreateOfferModal from './TermSheetCreateOfferModal';

type Props = {
  children: ReactNode;
  id: number;
};

type ContextProps = {
  showCreateOfferModal: () => void;
};

export const TermSheetContext = createContext<ContextProps>({
  showCreateOfferModal: () => null,
});

function TermSheetContextContextProvider({ id, children }: Props) {
  const [createOfferModal, showCreateOfferModal] = useModal(
    TermSheetCreateOfferModal,
    { id },
  );

  const value = useMemo<ContextProps>(
    () => ({ showCreateOfferModal }),
    [showCreateOfferModal],
  );

  return (
    <TermSheetContext.Provider value={value}>
      {children}
      {createOfferModal}
    </TermSheetContext.Provider>
  );
}

export default TermSheetContextContextProvider;

import React from 'react';
import { useGetTermSheetQuery } from 'src/entities/offers';
import TermSheetItems from './TermSheetItems';
import TermSheetContextContextProvider from './TermSheetContext';

type Props = {
  id: number;
};

function TermSheet({ id }: Props) {
  const { data, isFetching } = useGetTermSheetQuery(id);

  return (
    <TermSheetContextContextProvider id={id}>
      <TermSheetItems items={data} loading={isFetching} />
    </TermSheetContextContextProvider>
  );
}

export default TermSheet;

import Stack from '@mui/material/Stack';
import CaseIcon from 'src/assets/icons/case-icon.svg?react';
import { OfferItem } from 'src/entities/offers';
import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import TermSheetRow from './TermSheetRow';
import ListSkeleton from '../ListSkeleton';
import { TermSheetContext } from './TermSheetContext';

type Props = {
  items?: OfferItem[];
  loading?: boolean;
};

function TermSheetItems({ items, loading }: Props) {
  const { showCreateOfferModal } = useContext(TermSheetContext);

  if (loading || !items?.length) {
    return (
      <ListSkeleton loading={loading}>
        <Stack direction="column" alignItems="center" gap={2}>
          No offers yet for the loan application
          <Button
            size="small"
            startIcon={<SvgIcon component={CaseIcon} />}
            onClick={() => showCreateOfferModal()}
          >
            Create initial offer
          </Button>
        </Stack>
      </ListSkeleton>
    );
  }

  return (
    <Stack gap={2}>
      {items?.map((item) => <TermSheetRow key={item.id} {...item} />)}
    </Stack>
  );
}

export default TermSheetItems;

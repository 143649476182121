export enum OfferInitiatorType {
  Borrower = 'BORROWER',
  Creditor = 'CREDITOR',
}

export enum OfferVerificationStatus {
  UnderReview = 'UNDER_REVIEW',
  Verified = 'VERIFIED',
}

export enum OfferAcceptanceStatus {
  UnderReview = 'UNDER_REVIEW',
  CounterOffered = 'COUNTER_OFFERED',
  Accepted = 'ACCEPTED',
}

export enum CreateTermSheetForm {
  Values = 'values',
  Key = 'key',
  Value = 'value',
}

export type OfferValue = {
  key: string;
  value: string;
};

export type OfferItem = {
  id: number;
  loanApplicationId: number;
  initiatorType: OfferInitiatorType;
  creditorCompanyFullName: string;
  verificationStatusCode: OfferVerificationStatus;
  acceptanceStatusCode: OfferAcceptanceStatus;
  values: OfferValue[];
};

export type OffersTermSheet = {
  activeOffers: OfferItem[];
};

export type OfferValuesFormParams = {
  values: OfferValue[];
};

export type CreateOfferParams = OfferValuesFormParams & {
  id: number;
};

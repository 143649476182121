import * as Yup from 'yup';
import { CreateTermSheetForm } from 'src/entities/offers';
import { getValidationArrSchema } from 'src/helpers';

export const offerValuesRowValidation = {
  [CreateTermSheetForm.Key]: Yup.string().required('Please enter key'),
  [CreateTermSheetForm.Value]: Yup.string().required('Please enter Value'),
};

export const offerFormValidation = {
  [CreateTermSheetForm.Values]: getValidationArrSchema(
    offerValuesRowValidation,
  ),
};
